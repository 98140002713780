<template>
    <div class="modal-backdrop" @mousedown.self="closeModal">
        <div class="popup-content display--flex">
            <div class="__inner display--flex">
                <div class="popup-left">
                    <div class="popup-logo">
                        <img
                                src="@/assets/images/logo.svg"
                                alt="EISKILLS"
                                class="for-index hidden-sm"
                        />
                    </div>
                </div>
                <div class="popup-right">
                    <div class="popup-body display--flex">
                        <div class="popup-logo">
                            <img
                                    src="@/assets/images/logo-b.svg"
                                    alt="EISKILLS"
                                    class="visible-xs"
                            />
                        </div>
                        <button type="button" class="popup-close" @click="closeModal">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                        d="M4 4L12 12M20 20L12 12M12 12L20 4M12 12L4 20"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                />
                            </svg>
                        </button>
                        <slot>
                            <div v-if="state == 1" class="popup-container">
                                <div class="popup-title">{{ title }}</div>
                                <form @submit.prevent>
                                    <div class="form-group">
                                        <label>ФИО*</label>
                                        <input
                                                v-model="form.name"
                                                type="text"
                                                class="form-control"
                                                error="name"
                                                required
                                        />

                                    </div>
                                    <div class="form-group">
                                        <label>Email*</label>
                                        <input
                                                v-model="form.email"
                                                type="email"
                                                class="form-control"
                                                error="email"
                                                required
                                        />

                                    </div>
                                    <div class="form-group">
                                        <label>Телефон*</label>
                                        <vue-tel-input
                                                class="form-control"
                                                v-model="form.phone"
                                                error="phone"
                                                required
                                        ></vue-tel-input>

                                    </div>
                                    <div class="form-group">
                                        <label>Комментарий</label>
                                        <textarea
                                                v-model="form.comment"
                                                type="text"
                                                class="form-control"
                                                error="comment"
                                        />

                                    </div>
                                    <div class="form-action btn-action display--flex">
                                        <button
                                                @click="sendData"
                                                type="submit"
                                                class="btn btn-accent btn-action"
                                        >
                                            Отправить заявку
                                        </button>
                                    </div>
                                </form>

                                <div class="space"></div>
                            </div>

                            <div v-if="state == 2" class="popup-container">
                                <div class="popup-title">Мы получили ваше сообщение</div>
                                <p>Скоро мы вам ответим, спасибо за обращение!</p>
                                <div class="btn-action display--flex">
                                    <router-link
                                            to="/"
                                            @click="closeModal"
                                            class="btn btn-accent btn-sm"
                                    >На главную
                                    </router-link
                                    >
                                    <button
                                            @click="sendAgain"
                                            type="button"
                                            class="btn btn-o btn-sm"
                                    >
                                        Отправить еще раз
                                    </button>
                                </div>
                            </div>
                            <div v-if="state == 3" class="popup-container">
                                <div class="popup-title">Что-то пошло не так</div>
                                <p>Попробуйте еще раз</p>
                                <div class="btn-action display--flex">
                                    <button
                                            @click="sendAgain"
                                            type="button"
                                            class="btn btn-o btn-sm"
                                    >
                                        Отправить еще раз
                                    </button>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {reactive, ref, toRef} from "@vue/reactivity";
import {useStore} from "vuex";
import useErrors from "../../mixins/useErrors";
import {computed, onBeforeMount} from "@vue/runtime-core";
import {useRoute} from "vue-router";
import reportTranslate from "@/mixins/dictionaries/reportTranslate";

export default {
    components: {},
    name: "pop-up-form",
    props: {
        title: String,
        comment: String
    },
    setup(props, context) {
        const store = useStore(),
            {setErrors, clearCustomErrors} = useErrors(),
            route = useRoute();
        const profile = computed(() => store.state.profile.profile);
        const form = reactive({
            title: "",
            name: "",
            email: "",
            phone: "",
            comment: props.comment ? props.comment : route.params.nameProduct ? "Хочу оформить заказ на " + route.params.nameProduct : "Хочу оформить заказ на " + reportTranslate[route.params.product],
        });
        const state = ref(1)
        const sendData = async () => {
            let data = {
                title: route.params.id ? "referenceProfile" : route.params.name,
                name: form.name,
                email: form.email,
                phone: form.phone,
                comment: form.comment,
            }
            store
                .dispatch("sendForm", data)
                .then(() => {
                    state.value = 2;
                })
                .catch((error) => {
                    if (error?.response.status == 422) {
                        setErrors(error.response.data);
                        clearCustomErrors();
                    } else {
                        state.value = 3;
                    }
                });
        }

        let sendAgain = () => {
            state.value = 1;
        };

        const closeModal = () => {
            context.emit("closeModal");
        };

        onBeforeMount(() => {
            store.dispatch("profile/getProfile").then(() => {
                form.name = profile.value.last_name + ' ' + profile.value.first_name
                form.email = profile.value.email
            });
        })

        return {
            closeModal,
            setErrors,
            clearCustomErrors,
            form,
            sendData,
            state,
            sendAgain
        };
    },
};
</script>

<style lang="scss" scoped>
.form-control {
  display: inline-block;
  width: 100%;
  padding: 8px 16px !important;
  color: var(--color);
  background-color: #ffffff;
  border: 1px solid #b0afaf !important;
  border-radius: 20px !important;
  box-shadow: none;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin-top: 3px;
  display: flex;
}

.btn-action {
  border-radius: 20px !important;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.additional-info {
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin: 10px;
}

.popup-container {
  margin-right: 35px;
}

.popup-content {
  width: 80%;
  min-height: 80%;
  background-color: #edf2f8;
}

.popup-content .__inner {
  flex: 1;
}

.popup-left {
  position: relative;
  flex: 1;
  background: linear-gradient(180deg, #313554 0%, #3e4262 100%);
}

.popup-left::before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./../../assets/images/hero-green.svg);
  background-repeat: no-repeat;
  background-position: left bottom -10px;
  background-size: 81vw auto;
}

.popup-logo {
  position: absolute;
  display: block;
  top: 50px;
  left: 132px;
  width: 555px;
  height: 40px;
  text-align: left;
}

.popup-right {
  flex: 1;
  background-image: url(./../../assets/images/hero.svg);
  background-repeat: no-repeat;
  background-position: right -16px bottom -11px;
  background-size: 81vw auto;
}

.popup-body {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 600px;
  height: 100%;
  padding: 50px 0 50px 70px;
  font-size: 28px;
  line-height: 34px;
}

.popup-body p {
  margin: 0 0 30px 0;
}

.popup-close {
  position: absolute;
  display: flex;
  top: 54px;
  right: 32px;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  padding: 0;
  color: var(--color);
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.popup-close:hover {
  color: var(--accent);
}

.popup-close svg {
  width: 100%;
  height: 100%;
}

.popup-title {
  margin-bottom: 30px;
  font-size: 46px;
  line-height: 51px;
}

.popup-body form {
  padding: 35px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(182, 196, 214, 0.5);
}

.popup-body form .form-group + .form-group {
  margin-top: 20px;
}

.popup-body form .form-group + .form-action {
  margin-top: 35px;
}

@media (max-width: 1024px) {
  .popup-left {
    display: none;
  }
}

@media (max-width: 768px) {
  .popup-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .popup-body form .form-group + .form-action {
    margin-top: 25px;
  }

  .popup-logo {
    top: 32px;
    left: 32px;
  }

  .popup-container {
    margin-right: 0;
    padding-top: 73px;
    height: 100%;
  }

  .popup-close {
    top: 32px;
    right: 32px;
    width: 32px;
    height: 32px;
  }

  .popup-body {
    align-items: center;
    width: 100%;
    padding: var(--xs-continer-gap);
  }

  .popup-title {
    font-size: 36px;
    line-height: 43px;
  }

  .space {
    height: 15px;
  }
}

.checkboxes {
  font-size: 1.05rem;
  //word-spacing: 0.9%;
  margin: 15px 0 9px 0;

  label {
    padding-left: 26px;
    position: relative;
  }

  label::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    margin-right: 10px;
  }

  input {
    height: 0;
    width: 0;
  }

  input:checked ~ label::after {
    content: "";
    //background-image: url("./../../assets/images/icons/check.svg");
    background-size: cover;
    position: absolute;
    top: 3px;
    left: 0.5px;
    height: 19px;
    width: 19px;
    display: inline-block;
  }

  a {
    text-decoration: underline;
  }
}
</style>